import type React from 'react';
import { Key } from 'ts-keycode-enum';

export const onEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, cb: () => void | Promise<void>) => {
  const keyCode = event.which || event.keyCode;
  if (keyCode === Key.Enter) {
    cb();
  }
};

export const onKeyEnterAndSpace = (event: React.KeyboardEvent, cb: () => void | Promise<void>) => {
  const keyCode = event.which || event.keyCode;
  if (keyCode === Key.Enter || keyCode === Key.Space) {
    cb();
  }
};
